import React, { useRef } from "react"
import { Link } from "gatsby"
import Media from "react-media"
import classnames from "classnames"

import styles from "./header.module.scss"
import Menu from "./menu"
import Logo from "./logo"
import Container from "./container"

import SvgMenu from "../icons/menu.inline.svg"

const Header = ({ links, isMenuOpen, toggleMobileMenu }) => {
  const buttonEl = useRef(null)

  return (
    <header className={styles.Header}>
      <Container>
        <div className={styles.container}>
          <div className={styles.col1}>
            <Link
              to="/"
              className={styles.logoLink}
              title="Building Successful Websites | Ian Lunn - Front-end Developer"
            >
              <Logo />
            </Link>

            <a
              className={classnames(styles.link, styles.skipLink)}
              href="#content"
            >
              Skip to content
            </a>
          </div>
          <div className={styles.col2}>
            <Media queries={{ small: { maxWidth: 959 } }}>
              {matches =>
                matches.small ? (
                  <button
                    className={classnames(
                      styles.menuButton,
                      isMenuOpen ? styles.menuButtonOpen : ""
                    )}
                    ref={buttonEl}
                    title="Toggle menu"
                    onClick={() => toggleMobileMenu()}
                    aria-controls="drawer"
                  >
                    <SvgMenu
                      height="24px"
                      width="30px"
                      style={{ display: "block" }}
                    />
                  </button>
                ) : (
                  <div className={styles.menu}>
                    <Menu links={links} />
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
