import React from "react"
import Media from "react-media"
import { graphql } from "gatsby"
import classnames from "classnames"

import styles from "./post.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Updates from "../components/updates"
import Profile from "../components/profile"
import Ctas from "../components/ctas"

const post = ({
  data: {
    item: {
      html,
      excerpt,
      frontmatter: { date, title, subtitle, updates, ctas, socialImage },
    },
  },
}) => {
  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        image={socialImage ? socialImage.childImageSharp.fixed.src : null}
      />

      <Container>
        <div className={styles.grid}>
          <div className={classnames(styles.header, "header")}>
            {date && (
              <time className={styles.date} dateTime={date}>
                {date}
              </time>
            )}
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            {subtitle && <p className="subtitle">{subtitle}</p>}
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.col1}>
            {updates && (
              <div className={styles.updates}>
                <Updates updates={updates} />
              </div>
            )}

            {ctas && (
              <div className={styles.controls}>
                <Ctas items={ctas} />
              </div>
            )}

            <Media
              query="(min-width: 960px)"
              defaultMatches={false}
              render={() => (
                <div className={styles.sticky}>
                  <div className={styles.profile}>
                    <Profile />
                  </div>
                </div>
              )}
            />
          </div>

          <div className={styles.content}>
            <div id="carbon-ad" className={styles.carbonad} />
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>

          <Media
            query="(max-width: 959px)"
            defaultMatches={true}
            render={() => (
              <div className={styles.col1}>
                <div className={styles.profile}>
                  <Profile />
                </div>
              </div>
            )}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default post

export const pageQuery = graphql`
  query($slug: String!) {
    item: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "DD MMM YYYY")
        slug
        title
        subtitle
        updates {
          date(formatString: "DD MMM YYYY")
          content
        }
        ctas {
          text
          link
        }
        socialImage {
          childImageSharp {
            fixed(width: 1200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
