const links = [
  {
    to: "/",
    text: "Home",
    type: "link",
  },
  {
    to: "/portfolio",
    text: "Portfolio",
    type: "link",
  },
  {
    to: "/about",
    text: "About",
    type: "link",
  },
  {
    to: "/testimonials",
    text: "Testimonials",
    type: "link",
  },
  {
    to: "/articles",
    text: "Articles",
    type: "link",
  },
  {
    to: "/contact",
    text: "Contact",
    type: "button",
  },
]

export default links
