import React from "react"
import { Link, graphql } from "gatsby"

import styles from "./articles.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageContainer from "../components/pageContainer"
import Container from "../components/container"

const Articles = ({
  data: {
    allMarkdownRemark: {
      edges: articles,
      pageInfo: { currentPage, pageCount },
    },
  },
}) => {
  const isSecond = currentPage === 2;
  const isFirst = currentPage === 1
  const isLast = currentPage === pageCount
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <SEO
        title="Articles"
        description="Front-end development and web design articles from Ian Lunn"
      />

      <PageContainer>
        <Container>
          <div className="header align-center">
            <h1>Articles</h1>
          </div>
          <div className={styles.Articles}>
            <ul className={styles.list}>
              {articles.map(
                (
                  {
                    node: {
                      excerpt,
                      parent: { relativeDirectory: url },
                      frontmatter: { title, date },
                    },
                  },
                  index
                ) => (
                  <li className={styles.item} key={index}>
                    <time dateTime={date} className={styles.date}>
                      {date}
                    </time>
                    <Link to={`/${url}`} className={styles.title}>
                      {title}
                    </Link>

                    <div
                      className={styles.excerpt}
                      dangerouslySetInnerHTML={{ __html: excerpt }}
                    />
                  </li>
                )
              )}
            </ul>

            <div className={styles.paginate}>
              {
                // TODO: Remove need for isSecond - temp fix to work around URLs needing a forward-slash since Gatsby upgrade
                isSecond && (
                  <Link
                  to={`/articles`}
                  rel="prev"
                  className={styles.prev}
                >
                  Previous Page
                </Link>
                )
              }
              {!isFirst && !isSecond && (
                <Link
                  to={`/articles/${prevPage}`}
                  rel="prev"
                  className={styles.prev}
                >
                  Previous Page
                </Link>
              )}
              {!isLast && (
                <Link
                  to={`/articles/${nextPage}`}
                  rel="next"
                  className={styles.next}
                >
                  Next Page
                </Link>
              )}
            </div>
          </div>
        </Container>
      </PageContainer>
    </Layout>
  )
}

export default Articles

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: {
          regex: "/(?!articles/_archive)(?!articles/_empty)(articles)/"
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
