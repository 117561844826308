import React from "react"
import { Link, graphql } from "gatsby"
import classnames from "classnames"

import styles from "./index.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Portfolio from "../components/portfolio"
import ButtonSet from "../components/buttonSet"

const PortfolioPage = ({
  data: {
    portfolio,
    site: { siteMetadata },
  },
}) => {
  const title = "Front-end Development Portfolio"
  return (
    <Layout>
      <SEO title={title} description={siteMetadata.description} />

      <Container>
        <div className="header align-center">
          <h1 className={classnames("block-center", styles.title)}>
            {title}
          </h1>
          <p
            className={classnames(
              "subtitle",
              "block-center",
              styles.subtitlePortfolio
            )}
          >
            Succeed online with a website that is fast, easy to use, and built
            with best practices and a passion for design.
          </p>
        </div>

        <div id="portfolio">
          <Portfolio items={portfolio.edges} />

          <ButtonSet
            render={buttonSetStyles => (
              <>
                <div className={buttonSetStyles.button}>
                  <Link to="/contact" className="button">
                    Contact
                  </Link>
                </div>
                <div className={buttonSetStyles.link}>
                  <Link to="/testimonials">View testimonials</Link>
                </div>
              </>
            )}
          />
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        description
        profile {
          intro
        }
      }
    }

    portfolio: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        fileAbsolutePath: { glob: "**/portfolio/!(_empty|_archive)/index.md" }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            date(formatString: "DD MMM YYYY")
            title
            year
            slug
            excerpt
            thumb {
              childImageSharp {
                fluid(srcSetBreakpoints: [320, 450, 560, 640, 900, 1120]) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            previewSmall {
              childImageSharp {
                fluid(maxWidth: 825) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            previewMedium {
              childImageSharp {
                fluid(maxWidth: 990) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            previewLarge {
              childImageSharp {
                fluid(maxWidth: 1310) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`

export default PortfolioPage
