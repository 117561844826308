import React from "react"
import Media from "react-media"
import { graphql } from "gatsby"
import classnames from "classnames"

import styles from "./post.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Updates from "../components/updates"
import Screenshots from "../components/screenshots"
import Profile from "../components/profile"
import SimpleList from "../components/simpleList"
import Ctas from "../components/ctas"

const caseStudy = ({
  ads,
  data: {
    item: {
      html,
      frontmatter: {
        title,
        subtitle,
        tech,
        updates,
        ctas,
        previewSmall,
        previewMedium,
        previewLarge,
        excerpt,
      },
    },
  },
}) => {
  // Art-direction
  const sources = [
    {
      ...previewSmall.childImageSharp.fluid,
      media: `(max-width: 424px)`,
      aspectRatio: 850 / 1294,
    },
    {
      ...previewMedium.childImageSharp.fluid,
      media: `(min-width: 425px) and (max-width: 1399px)`,
      // Make sure this is set as Gatsby seems to calculate a slightly different aspect-ratio
      aspectRatio: 1880 / 1316,
    },
    {
      ...previewLarge.childImageSharp.fluid,
      media: `(min-width: 1400px)`,
      aspectRatio: 2620 / 1294,
    },
  ]

  return (
    <Layout>
      <SEO
        title={`${title} Case Study`}
        description={excerpt}
        image={previewLarge.childImageSharp.fluid.src}
      />

      <Container>
        <div className={styles.grid}>
          {ads && (
            <div className="carbon-ad">
              <script
                async
                type="text/javascript"
                src="//cdn.carbonads.com/carbon.js?serve=CKYIE23M&placement=ianlunncouk"
                id="_carbonads_js"
              />
            </div>
          )}

          <div className={classnames(styles.header, "header")}>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            {subtitle && (
              <p className="subtitle" style={{ maxWidth: "100%" }}>
                {subtitle}
              </p>
            )}
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.col2}>
            {excerpt && <p dangerouslySetInnerHTML={{ __html: excerpt }} />}
          </div>

          {sources && (
            <div className={styles.screenshots}>
              <Screenshots title={title} sources={sources} />
            </div>
          )}

          <div className={styles.col1}>
            {ctas && (
              <div className={styles.controls}>
                <Ctas items={ctas} />
              </div>
            )}

            {tech && (
              <div className={styles.tech}>
                <SimpleList title="Responsibilities" items={tech} />
              </div>
            )}

            {updates && (
              <div className={styles.updates}>
                <Updates updates={updates} />
              </div>
            )}

            <Media
              query="(min-width: 960px)"
              defaultMatches={false}
              render={() => (
                <div className={styles.profile}>
                  <Profile />
                </div>
              )}
            />
          </div>

          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Media
            query="(max-width: 959px)"
            defaultMatches={true}
            render={() => (
              <div className={styles.col1}>
                <div className={styles.profile}>
                  <Profile />
                </div>
              </div>
            )}
          />
        </div>
      </Container>
    </Layout>
  )
}

caseStudy.defaultProps = {
  ads: false,
}

export default caseStudy

export const pageQuery = graphql`
  query($slug: String!) {
    item: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD MMM YYYY")
        slug
        title
        subtitle
        tech
        updates {
          date(formatString: "DD MMM YYYY")
          content
        }
        ctas {
          text
          link
        }
        excerpt
        previewSmall {
          childImageSharp {
            fluid(maxWidth: 825) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        previewMedium {
          childImageSharp {
            fluid(maxWidth: 990) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        previewLarge {
          childImageSharp {
            fluid(maxWidth: 1310) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
