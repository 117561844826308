import React, { useEffect, useRef } from "react"
import classnames from "classnames"

import styles from "./newDrawer.module.scss"
import SvgTwitter from "../icons/twitter.inline.svg"
import SvgGithub from "../icons/github.inline.svg"
import SvgLinkedIn from "../icons/linkedin.inline.svg"

import SvgClose from "../icons/cross.inline.svg"

const NewDrawer = ({ isOpen, closeMenu, children }) => {
  const closeButton = useRef(null)

  // Focus on the close button when opened
  useEffect(() => {
    if (isOpen) {
      closeButton.current.focus()
    }
  })
  return (
    <div
      id="drawer"
      className={classnames(
        styles.NewDrawer,
        isOpen ? styles.NewDrawerOpen : ""
      )}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <ul className={styles.social}>
            <li className={styles.socialItem}>
              <a
                className={styles.socialLink}
                href="https://twitter.com/IanLunn/"
                title="Ian Lunn on Twitter"
              >
                <span className={styles.icon}>
                  <SvgTwitter
                    width="1.25em"
                    height="1.25em"
                    style={{ display: "block" }}
                  />
                </span>
              </a>
            </li>
            <li className={styles.socialItem}>
              <a
                className={styles.socialLink}
                href="https://github.com/IanLunn/"
                title="Ian Lunn on GitHub"
              >
                <span className={styles.icon}>
                  <SvgGithub
                    width="1.25em"
                    height="1.25em"
                    style={{ display: "block" }}
                  />
                </span>
              </a>
            </li>
            <li className={styles.socialItem}>
              <a
                className={styles.socialLink}
                href="https://uk.linkedin.com/in/ianlunn/"
                title="Ian Lunn on LinkedIn"
              >
                <span className={styles.icon}>
                  <SvgLinkedIn
                    width="1.25em"
                    height="1.25em"
                    style={{ display: "block" }}
                  />
                </span>
              </a>
            </li>
          </ul>
          <button
            ref={closeButton}
            className={styles.button}
            title={isOpen ? "Close menu" : "Open menu"}
            onClick={e => {
              closeMenu()
            }}
          >
            <SvgClose height="24px" width="30px" style={{ display: "block" }} />
          </button>
        </div>
        <div className={styles.menu}>{children}</div>
      </div>

      <div
        aria-hidden
        className={styles.overlay}
        onClick={() => {
          if (isOpen) {
            closeMenu()
          }
        }}
      />
    </div>
  )
}

export default NewDrawer
