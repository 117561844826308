import React, { useRef, useEffect } from "react"
import styles from "./logo.module.scss"

import SvgName from "../icons/name.inline.svg"

const Logo = () => {
  let animationTimer

  useEffect(() => {
    clearTimeout(animationTimer)
  }, [animationTimer])

  const iconRef = useRef()

  return (
    <div
      className={styles.Logo}
      role="presentation"
      onMouseEnter={e => {
        var el = iconRef.current
        el.classList.add("active")
        animationTimer = setTimeout(function () {
          if (iconRef.current) {
            iconRef.current.classList.remove("active")
          }
        }, 600)
      }}
    >
      <div className={styles.ident}>
        <svg
          ref={iconRef}
          className={styles.icon}
          xmlns="https://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <g className={styles.circleHolder}>
          <circle className={styles.circle}  cx="26" cy="26" r="26"></circle>
          </g>
          <path className={styles.i} d="M15 22h9v16h-9v-16z"></path>
          <path className={styles.l} d="M28 14h9v24h-9v-24z"></path>
        </svg>
      </div>
      <div className={styles.name}>
        <SvgName width="100%" height="100%" />
      </div>
    </div>
  )
}

export default Logo
