import React, { useState } from "react"
import { graphql } from "gatsby"
import classnames from "classnames"
import Img from "gatsby-image"
import { getAvailableDate } from "../utils"

import styles from "./contact.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Checkbox from "../components/checkbox"
import SROnly from "../components/sronly"
import Promo from "../components/promoBar"
import Box from "../components/box"

import SvgTwitter from "../icons/twitter.inline.svg"
import SvgGithub from "../icons/github.inline.svg"
import SvgLinkedIn from "../icons/linkedin.inline.svg"

let offer = ""

const PromoContainer = () => {
  if (typeof window !== `undefined`) {
    let params = new URLSearchParams(window.location.search)
    offer = params.get("offer")

    if (offer === "email25") {
      return (
        <Container>
          <div className={styles.promo}>
            <Promo>
              <strong>Email offer</strong>: 25% discount available on all
              services. Get in touch today!
            </Promo>
          </div>
        </Container>
      )
    }
  }

  return null
}

const ContactPage = ({ data }) => {
  const [isProjectRequest, setIsProjectRequest] = useState(false)

  let availableDate = getAvailableDate(
    data.site.siteMetadata.profile.availability
  )

  return (
    <Layout>
      <SEO
        title="Contact Ian Lunn"
        description="Contact Ian Lunn - Front-end Developer. Available for front-end development and WordPress projects."
      />
      <Container>
        <div className={styles.Contact}>
          <PromoContainer />

          <div className={styles.grid}>
            <div className={classnames(styles.col, styles.col1)}>
              <div className="header">
                <h1>Contact</h1>
                <p className="subtitle">
                  Send a message.
                </p>
              </div>

              <form
                action="/thankyou"
                className={styles.form}
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <fieldset>
                  <legend>About You</legend>

                  <div className={styles.rows}>
                    <div>
                      <label htmlFor="name">
                        Your name
                        <span className="req" title="required">
                          *
                        </span>
                      </label>
                      <input
                        className="full"
                        id="name"
                        type="text"
                        name="name"
                        required
                        placeholder="eg, Ian Lunn"
                      />
                    </div>

                    <div>
                      <label htmlFor="email">
                        Email
                        <span className="req" title="required">
                          *
                        </span>
                      </label>
                      <input
                        className="full"
                        id="email"
                        type="email"
                        name="email"
                        required
                        placeholder="hello@ianlunn.co.uk"
                      />
                    </div>

                    <div>
                      <label htmlFor="website">Your website</label>
                      <input
                        className="full"
                        id="website"
                        type="text"
                        name="website"
                        placeholder="eg, https://ianlunn.co.uk"
                      />
                    </div>
                  </div>
                </fieldset>

                

                <fieldset>
                  <label className={styles.legend} htmlFor="message">
                    General Message
                    <span className="req" title="required">
                      *
                    </span>
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="6"
                    required="required"
                  />
                </fieldset>

                <SROnly>
                  <label>
                    Don't fill this out if you're human:{" "}
                    <input name="bot-field" />
                  </label>
                </SROnly>

                <button type="submit" className="button">
                  Send
                </button>
              </form>
            </div>
            <div
              className={classnames(styles.col, styles.col2, styles.sidebar)}
            >
              <hr className={styles.mobileBreak} />

              <h2 style={{ marginTop: ".98em" }}>About Ian Lunn</h2>
              <div
                style={{
                  float: "left",
                  marginRight: "1em",
                  marginTop: ".75em",
                }}
              >
                <Img
                  fadeIn={false}
                  fixed={data.avatar.childImageSharp.fixed}
                  alt="Photo of Ian Lunn"
                  style={{
                    display: "block",
                  }}
                />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.site.siteMetadata.profile.bio,
                }}
              />

              <ul className={styles.contactList}>
                <li className={styles.contactListItem}>
                  Email:{" "}
                  <strong>
                    <a href="mailto:hello@ianlunn.co.uk">hello@ianlunn.co.uk</a>
                  </strong>
                </li>
                <li className={styles.contactListItem}>
                  Tel:{" "}
                  <strong>
                    <a href="tel://+441172303023">+44 (0)117 2303023</a>
                  </strong>
                </li>
              </ul>

              <ul className={styles.social}>
                <li className={styles.socialItem}>
                  <a
                    className={styles.socialLink}
                    href="https://twitter.com/IanLunn/"
                    title="Ian Lunn on Twitter"
                  >
                    <span className={styles.icon}>
                      <SvgTwitter
                        width="1.5em"
                        height="1.5em"
                        style={{ display: "block" }}
                      />
                    </span>
                  </a>
                </li>
                <li className={styles.socialItem}>
                  <a
                    className={styles.socialLink}
                    href="https://github.com/IanLunn/"
                    title="Ian Lunn on GitHub"
                  >
                    <span className={styles.icon}>
                      <SvgGithub
                        width="1.5em"
                        height="1.5em"
                        style={{ display: "block" }}
                      />
                    </span>
                  </a>
                </li>
                <li className={styles.socialItem}>
                  <a
                    className={styles.socialLink}
                    href="https://www.linkedin.com/in/ianlunn/"
                    title="Ian Lunn on LinkedIn"
                  >
                    <span className={styles.icon}>
                      <SvgLinkedIn
                        width="1.5em"
                        height="1.5em"
                        style={{ display: "block" }}
                      />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        profile {
          bio
          availability
        }
      }
    }

    avatar: file(relativePath: { eq: "avatar-small@2x.jpg" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    gettingStarted: file(relativePath: { eq: "getting-started-single.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ContactPage
