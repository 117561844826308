import React from "react"
import { Link, graphql } from "gatsby"
import classnames from "classnames"
import Img from "gatsby-image"

import styles from "./index.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Portfolio from "../components/portfolio"
import Testimonials from "../components/testimonials"
import SROnly from "../components/sronly"
import ButtonSet from "../components/buttonSet"

const IndexPage = ({
  data: {
    featuredTestimonials,
    serviceWeb,
    serviceTools,
    serviceWP,
    portfolio,
    site: { siteMetadata },
  },
}) => {
  const title = "Successful Front-end Development"
  return (
    <Layout>
      <SEO title={title} description={siteMetadata.description} />

      <Container>
        <div className="header align-center">
          <h1 className={classnames("block-center", styles.title)}>{title}</h1>
          <p
            className={classnames("subtitle", "block-center", styles.subtitle)}
          >
            Hi. I'm Ian, a Front-end Developer with {siteMetadata.profile.yearsExperience} years
            commercial experience creating successful websites.
          </p>
        </div>

        <div id="services" className={styles.services}>
          <SROnly>
            <h2>Services</h2>
          </SROnly>
          <div className={styles.servicesContainer}>
            <div
              className={classnames(styles.servicesCol, styles.servicesCol1)}
            >
              <div className={styles.servicesBox}>
                <div className={styles.servicesImage}>
                  <div style={{ width: "100%" }}>
                    <Img
                      fadeIn={false}
                      fluid={{
                        ...serviceWeb.childImageSharp.fluid,
                        aspectRatio: 441 / 649,
                      }}
                      alt="An illustration of a mobile phone showing a simple grid layout on the screen"
                    />
                  </div>
                </div>
                <div className={styles.servicesContent}>
                  <h3 className={styles.servicesTitle}>
                    Front-end development
                  </h3>
                  <p className={styles.servicesDesc}>
                    Responsive websites built for an optimal user experience
                    that achieves your business goals.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={classnames(styles.servicesCol, styles.servicesCol2)}
            >
              <div className={styles.servicesBox}>
                <div className={styles.servicesImage}>
                  <div style={{ width: "100%" }}>
                    <Img
                      fadeIn={false}
                      fluid={{
                        ...serviceTools.childImageSharp.fluid,
                        aspectRatio: 445 / 627,
                      }}
                      alt="An illustration of a screwdriver and monkey wrench"
                    />
                  </div>
                </div>
                <div className={styles.servicesContent}>
                  <h3 className={styles.servicesTitle}>
                    Performance, SEO, &<br /> accessibility testing
                  </h3>
                  <p className={styles.servicesDesc}>
                    Make your website fast, easy to find, and reach the widest
                    audience possible.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={classnames(styles.servicesCol, styles.servicesCol3)}
            >
              <div className={styles.servicesBox}>
                <div className={styles.servicesImage}>
                  <div style={{ width: "100%" }}>
                    <Img
                      fadeIn={false}
                      fluid={{
                        ...serviceWP.childImageSharp.fluid,
                        aspectRatio: 440 / 648,
                      }}
                      alt="An illustration of a browser window displaying the WordPress logo"
                    />
                  </div>
                </div>
                <div className={styles.servicesContent}>
                  <h3 className={styles.servicesTitle}>WordPress websites</h3>
                  <p className={styles.servicesDesc}>
                    Manage your website using the web's most popular content
                    management system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br />
        <div id="portfolio">
          <h2 className={classnames("align-center", styles.sectionTitle)}>
            Featured Work
          </h2>
          <Portfolio items={portfolio.edges} />

          <ButtonSet
            render={buttonSetStyles => (
              <>
                <div className={buttonSetStyles.button}>
                  <Link to="/contact" className="button">
                    Contact
                  </Link>
                </div>
                <div className={buttonSetStyles.link}>
                  <Link to="/portfolio">View all case-studies</Link>
                </div>
              </>
            )}
          />
        </div>
        
        <br /><br />
        <div id="testimonials">
          <h2 className={classnames(styles.sectionTitle, "align-center")}>
            Testimonials
          </h2>

          <Testimonials items={featuredTestimonials.edges} />

          <ButtonSet
            render={buttonSetStyles => (
              <>
                <div className={buttonSetStyles.button}>
                  <Link to="/contact" className="button">
                    Contact
                  </Link>
                </div>
                <div className={buttonSetStyles.link}>
                  <Link to="/testimonials">View all testimonials</Link>
                </div>
              </>
            )}
          />
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        description
        profile {
          intro
          yearsExperience
        }
      }
    }

    portfolio: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        fileAbsolutePath: { glob: "**/portfolio/!(_empty|_archive)/index.md" }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            date(formatString: "DD MMM YYYY")
            title
            year
            slug
            excerpt
            thumb {
              childImageSharp {
                fluid(srcSetBreakpoints: [320, 450, 560, 640, 900, 1120]) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            previewSmall {
              childImageSharp {
                fluid(maxWidth: 825) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            previewMedium {
              childImageSharp {
                fluid(maxWidth: 990) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            previewLarge {
              childImageSharp {
                fluid(maxWidth: 1310) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }

    featuredTestimonials: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        fileAbsolutePath: {
          regex: "/(?!testimonials/_archive)(?!testimonials/_empty)(testimonials)/"
        }
        frontmatter: { featured: { eq: true } }
      }
      limit: 3
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            link
            organisation
            avatar {
              childImageSharp {
                fluid(maxWidth: 120) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    serviceWeb: file(relativePath: { eq: "feature__web.png" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    serviceTools: file(relativePath: { eq: "feature__tools.png" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    serviceWP: file(relativePath: { eq: "feature__wp.png" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bridgeCreativeLogo: file(
      relativePath: { eq: "bridge-creative-logo@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    petplateLogo: file(relativePath: { eq: "petplate-logo@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    r3mxLogo: file(relativePath: { eq: "r3mx-logo@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 119) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
