import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "./portfolio.module.scss"

import SROnly from "./sronly"
import SvgChevronRight from "../icons/chevron-right.inline.svg"
import Screenshots from "./screenshots"

const Portfolio = ({ items }) => (
  <div className={styles.Portfolio}>
    {items.map(
      (
        {
          node: {
            frontmatter: {
              title,
              year,
              excerpt,
              thumb,
              previewSmall,
              previewMedium,
              previewLarge,
            },
            parent: { relativeDirectory: url },
          },
        },
        index
      ) => {
        // Art-direction
        const sources = [
          {
            ...previewSmall.childImageSharp.fluid,
            media: `(max-width: 1199px)`,
            aspectRatio: 850 / 1294,
          },
          {
            ...previewMedium.childImageSharp.fluid,
            media: `(min-width: 1200px) and (max-width: 1599px)`,
            // Make sure this is set as Gatsby seems to calculate a slightly different aspect-ratio
            aspectRatio: 1880 / 1316,
          },
          {
            ...previewLarge.childImageSharp.fluid,
            media: `(min-width: 1600px)`,
            aspectRatio: 2620 / 1294,
          },
        ]

        const loading = index === 0 ? "eager" : "lazy"

        return (
          <div key={index} className={styles.item}>
            <div className={styles.col1}>
              <div className={styles.links}>
                <Link to={`/${url}`} title={`${title}`} className={styles.link}>
                  {thumb && (
                    <div className={styles.thumbContainer}>
                      <Img
                        loading={loading}
                        fadeIn={false}
                        fluid={{
                          ...thumb.childImageSharp.fluid,
                          aspectRatio: 1120 / 630,
                        }}
                        className={styles.thumb}
                      />
                    </div>
                  )}

                  <div className={styles.name}>
                    <SvgChevronRight height="1em" className={styles.nameIcon} />
                    <span>
                      {title} <SROnly>case study</SROnly>
                    </span>
                  </div>
                </Link>
              </div>
              <div className={styles.content}>
                <p
                  className={styles.excerpt}
                  dangerouslySetInnerHTML={{ __html: excerpt }}
                />
              </div>
            </div>
            <div className={styles.col2}>
              <Screenshots
                url={`/${url}`}
                title={title}
                sources={sources}
                loading={loading}
              />
            </div>
          </div>
        )
      }
    )}
  </div>
)

export default Portfolio
